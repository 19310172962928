<template>
  <JContainer class="shadow-b-lg border-y border-line py-8">
    <div
      class="mx-auto flex justify-between gap-4 text-metadata-limited md:items-center md:gap-0 md:text-base"
    >
      <div
        class="flex w-full flex-col gap-x-8 md:flex-row md:items-center [&>div:first-of-type]:ps-0 [&>div:last-of-type]:border-none [&>div]:pe-8 md:[&>div]:border-e md:[&>div]:border-line"
      >
        <div
          class="scrollbar-hidden mb-4 flex select-none items-center gap-x-8 overflow-x-auto md:mb-0 md:max-w-4xl"
        >
          <span v-for="(item, index) in destinations" :key="index" class="flex">
            <span
              class="w-max font-medium"
              :class="index !== destinations.length - 1 && 'pe-8'"
              >{{ item.title }}</span
            >

            <JIcon
              v-if="index !== destinations.length - 1"
              name="airplane"
              size="20"
              class="text-gray-600"
            />
          </span>
        </div>

        <div
          class="flex w-full items-center justify-between md:w-max md:flex-1"
        >
          <div
            class="flex items-center gap-x-8 [&>div:last-of-type]:border-none [&>div]:border-e [&>div]:border-line [&_div]:pe-8"
          >
            <div class="flex items-center gap-x-4 text-gray-600">
              <JIcon
                v-if="!isMobile"
                :name="
                  searchPanelHistory.history.isRoundTrip
                    ? 'round-trip-rounded'
                    : 'oneway-trip'
                "
                size="20"
              />
              <span>
                {{ $t(`search.${tripType}`) }}
              </span>
            </div>

            <div class="flex items-center gap-x-4 text-gray-600">
              <JIcon v-if="!isMobile" name="add-user-linear" size="20" />
              <span class="inline-flex items-center">
                {{ passengersText }}
              </span>
            </div>

            <div class="flex items-center gap-x-4 text-gray-600">
              <JIcon v-if="!isMobile" name="calendar-outline" size="18" />
              <span> {{ dateOfTravel }} </span>
            </div>
          </div>

          <button
            class="flex items-center gap-x-4 rounded-md border border-line bg-gray-100"
            :class="isMobile ? 'p-8' : 'p-4'"
            @click="onEditSearch"
          >
            <JIcon
              :name="isMobile ? 'pen-outline' : 'magnifier-outline'"
              size="16"
            />
            <span v-if="!isMobile" class="font-medium">
              {{ $t('words.edit') }} {{ $t('words.search') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </JContainer>
</template>

<script lang="ts" setup>
import { JContainer, JIcon } from '@journey/ui-kit'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { parse } from 'date-fns'
import useSearchPanelHistoryStore from '@/modules/Search/store/searchPanelHistory'
import type { DestinationLocation } from '@/modules/Search/types/model/destination'
import type { Airport } from '@/modules/Profile/types/model/airport'
import type { InterestCity } from '@/modules/Search/types/model/interestCity'
import { useDevicePlatform, useFormatDate } from '@/modules/Core/composables'
import { DATE_FORMAT } from '@/modules/Search/components/SearchPanel/SearchPanelForm/SearchPanelFormFields/SearchPanelFormFields.constants'

const { t } = useI18n()

const router = useRouter()

const searchPanelHistory = useSearchPanelHistoryStore()
const { isMobile } = useDevicePlatform()

const passengersText = computed(() => {
  const { totalAdultPassengers, totalChildPassengers } =
    searchPanelHistory.history

  let text = `${totalAdultPassengers} ${t('book.adult', totalAdultPassengers)}`

  if (totalChildPassengers) {
    const childrenLabel =
      totalChildPassengers > 1
        ? t('search.form.children.label.plural')
        : t('search.form.children.label.singular')

    text += `, ${totalChildPassengers} ${childrenLabel}`
  }

  return text
})

function getLocationTitle(
  destination: DestinationLocation | Airport | InterestCity | undefined
) {
  if (!destination) {
    return ''
  }

  return destination.geoCity
}

function createItem(
  destination: DestinationLocation | Airport | InterestCity | undefined
) {
  return {
    title: getLocationTitle(destination) ?? '',
  }
}

const destinations = computed(() => {
  if (
    searchPanelHistory.history.isSearchByDestination ||
    searchPanelHistory.history.isSearchByEvents
  ) {
    return [
      searchPanelHistory.history.main.origin,
      ...searchPanelHistory.history.destinationList.map(
        (item) => item.destination
      ),
    ].flatMap((item) => createItem(item))
  }

  return searchPanelHistory.history.attributeOptions.flatMap(createItem)
})

const tripType = computed(() => {
  const type = searchPanelHistory.history.main.tripType
  return type.charAt(0).toLocaleLowerCase() + type.slice(1)
})

const DATE_FORMAT_DISPLAY = 'EEE d MMM'

function parseDate(dateString: string) {
  return parse(dateString, DATE_FORMAT, new Date()).toISOString()
}

const formattedDepartureDate = computed(() => {
  const formattedDate = parseDate(searchPanelHistory.history.main.departureTime)

  return useFormatDate(formattedDate, DATE_FORMAT_DISPLAY).formattedDate.value
})

const formattedArrivalDate = computed(() => {
  if (searchPanelHistory.history.isSearchByEvents) {
    return useFormatDate(
      parseDate(searchPanelHistory.history.main.arrivalTime),
      DATE_FORMAT_DISPLAY
    ).formattedDate.value
  }

  const lastDestination =
    searchPanelHistory.history.destinationList.slice(-1)[0]

  return lastDestination?.arrivalTime
    ? useFormatDate(parseDate(lastDestination.arrivalTime), DATE_FORMAT_DISPLAY)
        .formattedDate.value
    : ''
})

const dateOfTravel = computed(
  () =>
    `${formattedDepartureDate.value}${
      formattedArrivalDate.value ? ` - ${formattedArrivalDate.value}` : ''
    }`
)

function onEditSearch() {
  if (isMobile) {
    router.push({ name: 'Search' })

    return
  }

  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>
