import type { InjectionKey } from 'vue/dist/vue'
import type { HotelInfoWithRates } from '@/modules/Search/types'
import type { Car } from '@/modules/Search/types/model/search'

export const MIN_CAR_SEATS = 2
export const MAX_CAR_SEATS = 8
export const GOOGLE_MAP_API_KEY = import.meta.env.VITE_GOOGLE_MAP_API_KEY
export const MINIMUM_RESULT_SIZE = 4
export const MINIMUM_CAR_CAPACITY = 2
export const DEFAULT_FLIGHT_PAGE_SIZE = 12
export const DEFAULT_HOTEL_PAGE_SIZE = 12
export const DEFAULT_CAR_PAGE_SIZE = 12
export const DEFAULT_REFRESH_TIMER = 30 * 60 * 1000
export const HEADER_SCROLL_OFFSET_AFTER_SEARCH = 90
export const CITY_PAGE_SIZE = 6

export const showHotelDetailsInjectionKey = Symbol(
  'showHotelDetailsInjectionKey'
) as InjectionKey<{ showHotelDetail: (hotel: HotelInfoWithRates) => void }>
export const showCarDetailsInjectionKey = Symbol(
  'showCarDetailsInjectionKey'
) as InjectionKey<{ showCarDetail: (car: Car) => void }>

export const GOOGLE_MAP_URL = 'https://www.google.com/maps/search/?api=1&query='
export const APPLE_MAP_URL = 'http://maps.apple.com/?ll='

export const EVENT_FORM_DEFAULT_VALUES = {
  typeIds: [],
  artistIds: [],
  genreIds: [],
  leagueIds: [],
  homeTeamId: undefined,
  awayTeamId: undefined,
  locationIds: [],
  keywords: [],
  sport: '',
}

export function basePassengerInfo() {
  return {
    adults: 1,
    children: 0,
    childrenAges: [],
  }
}
